
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getRecepcionistsOfEvent,
  getAllRecepcionists,
  attachRecepcionistToEvent,
  detachRecepcionistFromEvent,
} from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'

@Component({
  components: {
    SellerHeader,
  },
})
export default class ListRecepcionists extends Vue {
  eventId!: number = localStorage.getItem('selectedEvent')

  recepcionistsOfEvent: [] = []
  allRecepcionists: [] = []

  allRecepcionistsFiltered: [] = []
  allRecepcionistsTotalPages = 1
  allRecepcionistsPage = 1

  filter = ''

  async getRecepcionistsOfEvent() {
    try {
      const data = await getRecepcionistsOfEvent(this.eventId)
      this.recepcionistsOfEvent = data.data.data
      this.recepcionistsOfEventFiltered = this.recepcionistsOfEvent
    } catch (error) {
      console.error(error)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async getAllRecepcionists(page?, query?) {
    try {
      const { meta, data } = await getAllRecepcionists(
        this.eventId,
        page,
        query
      )
      this.allRecepcionistsTotalPages = meta.last_page

      this.allRecepcionists = data
      this.allRecepcionistsFiltered = this.allRecepcionists
    } catch (error) {
      console.error(error)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async attachRecepcionistToEvent(recepcionist_id) {
    try {
      const response: any = await attachRecepcionistToEvent(
        this.eventId,
        recepcionist_id
      )

      if (response.status == 200) {
        Vue.$toast.success(`Recepcionista asociado correctamente!`)
        this.getRecepcionistsOfEvent()
        this.getAllRecepcionists(this.allRecepcionistsPage, this.filter)
      } else {
        Vue.$toast.error('Ha ocurrido un error asociando el recepcionista')
      }
    } catch (e) {
      console.error(e)
      Vue.$toast.error('Ha ocurrido un error asociando el recepcionista')
    }
  }

  async detachRecepcionistFromEvent(recepcionist_id) {
    try {
      const response: any = await detachRecepcionistFromEvent(
        this.eventId,
        recepcionist_id
      )

      if (response.status == 200) {
        Vue.$toast.success(`Recepcionista desasociado correctamente!`)
        this.getRecepcionistsOfEvent()
      } else {
        Vue.$toast.error('Ha ocurrido un error desasociando el recepcionista')
      }
    } catch (e) {
      console.error(e)
      Vue.$toast.error('Ha ocurrido un error desasociando el recepcionista')
    }
  }


  

  mounted() {
    this.getRecepcionistsOfEvent()
  }

  @Watch('allRecepcionistsPage', { immediate: true, deep: true })
  onallRecepcionistsPageChange(nextPage: number, _currentPage: unknown) {
    this.getAllRecepcionists(nextPage, this.filter)
  }
}
